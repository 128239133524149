import React, { useState } from "react";

const Landing = () => {
  const contractAddress = '0xB43c4875f2b0Bf464f9D747A0caae9FD8E754252';
  const [text] = useState(contractAddress)
  const handleCopy = () => {
    if (navigator.clipboard && window.isSecureContext)
        navigator.clipboard.writeText(text)
    else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
  }

  const openUnisWapPage = () => {
    window.open(`https://app.uniswap.org/#/swap?outputCurrency=${contractAddress}`, '_blank');
  }

  return (
    <div>
      <div className="relative">
        <img className="hidden md:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover h-full 2xl:w-full" src="images/radial.png" alt="" />
        <img className="block md:hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full" src="images/radial-mobile.png" alt="" />
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex justify-center md:justify-between items-center pt-[35px] md:pt-0 md:pl-[48px] md:pr-[98.57px] md:h-[100px] relative z-0">
            <img className="hidden md:flex h-[40px]" src="images/logo-sm.png" alt="" />
            <div className="flex items-center">
              <a href="https://t.me/jeetcommunity" target="_blank" rel="noreferrer" className="flex justify-center items-center space-x-[3.79px] w-[43.03px] h-[40px] bg-white text-black1 hover:text-orange1 transition border-2 border-black1 rounded-[30px] shadow-md">
                <svg width="23" height="18" viewBox="0 0 23 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.7283 1.63827L19.4083 16.9135C19.1577 17.9914 18.5047 18.2597 17.5766 17.7522L12.5177 14.1153L10.077 16.406C9.80666 16.6697 9.58113 16.8897 9.06015 16.8897L9.42397 11.8637L18.7993 3.59856C19.2072 3.24437 18.7105 3.04735 18.166 3.4023L6.57546 10.5228L1.58564 8.99874C0.500457 8.66831 0.480812 7.94001 1.81195 7.43173L21.3288 0.0958002C22.2325 -0.23462 23.023 0.291292 22.7283 1.63827Z" />
                </svg>
              </a>
              <a href="https://twitter.com/Jeet_Token" target="_blank" rel="noreferrer" className="ml-[11px] flex justify-center items-center space-x-[3.79px] w-[43.03px] h-[40px] bg-white text-black1 hover:text-orange1 transition border-2 border-black1 rounded-[30px] shadow-md">
                <svg width="22" height="17" viewBox="0 0 22 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.711 2C20.941 2.35 20.111 2.58 19.251 2.69C20.131 2.16 20.811 1.32 21.131 0.31C20.301 0.81 19.381 1.16 18.411 1.36C17.621 0.5 16.511 0 15.251 0C12.901 0 10.981 1.92 10.981 4.29C10.981 4.63 11.021 4.96 11.091 5.27C7.53102 5.09 4.36102 3.38 2.25102 0.79C1.88102 1.42 1.67102 2.16 1.67102 2.94C1.67102 4.43 2.42102 5.75 3.58102 6.5C2.87102 6.5 2.21102 6.3 1.63102 6V6.03C1.63102 8.11 3.11102 9.85 5.07102 10.24C4.44174 10.4122 3.78111 10.4362 3.14102 10.31C3.41262 11.1625 3.94455 11.9084 4.66203 12.4429C5.37951 12.9775 6.24646 13.2737 7.14102 13.29C5.62465 14.4904 3.74501 15.1393 1.81102 15.13C1.47102 15.13 1.13102 15.11 0.791016 15.07C2.69102 16.29 4.95102 17 7.37102 17C15.251 17 19.581 10.46 19.581 4.79C19.581 4.6 19.581 4.42 19.571 4.23C20.411 3.63 21.131 2.87 21.711 2Z" />
                </svg>
              </a>
              <button onClick={() => openUnisWapPage()} className="ml-[12.6px] w-[150px] h-[40px] bg-white transition border-2 border-black1 rounded-[30px] font-medium text-black1 hover:text-orange1 text-[16px] leading-[150%] shadow-md">
                <div className="flex justify-center items-end space-x-[3.79px] mt-[-4px]">
                  <svg width="20" height="25" viewBox="0 0 20 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.43474 11.9624C9.93792 12.9032 8.80575 13.172 8.30257 13.2392C7.54779 13.3065 7.35909 12.836 7.48489 12.2312C7.54779 12.0296 7.67359 11.828 7.79938 11.6936C7.92518 11.5591 8.17677 11.4247 8.36547 11.4247C8.55416 11.4247 8.80575 11.4247 8.93155 11.5591C9.18314 11.6263 9.30894 11.7608 9.43474 11.9624Z" />
                    <path d="M12.4539 10.2823C11.8878 14.9866 19.5614 13.9785 19.4356 17.6747C20.1903 16.5995 20.5048 13.7769 18.3034 12.2984C16.2906 10.9543 13.7118 11.6935 12.4539 10.2823Z" />
                    <path d="M16.9196 8.46774C16.8567 8.40054 16.7938 8.40054 16.7938 8.33333C16.7938 8.40054 16.8567 8.46774 16.9196 8.46774Z" />
                    <path d="M18.6808 11.8952C18.6179 11.7608 18.555 11.6263 18.4292 11.4919C18.1776 11.0887 17.8002 10.8199 17.3599 10.7527C17.1083 10.6855 16.7938 10.6183 16.4793 10.6183C16.1648 10.6183 15.9133 10.5511 15.5988 10.5511C14.9698 10.4839 14.4037 10.4167 13.7747 10.2823C13.6489 10.2151 13.4602 10.2151 13.3344 10.1478C13.2715 10.1478 13.2086 10.0806 13.0828 10.0806C13.0199 10.0134 12.957 10.0134 12.8312 9.94624C12.5168 9.81183 12.2652 9.54301 12.0765 9.3414C11.6362 8.87097 11.2588 8.33333 10.8814 7.8629C10.504 7.32527 10.1895 6.85484 9.74923 6.38441C9.37184 5.91398 8.86865 5.51075 8.36547 5.24194C7.86228 4.97312 7.2333 4.77151 6.66721 4.7043C7.2962 4.6371 7.92518 4.7043 8.49126 4.97312C9.05735 5.24194 9.62343 5.57796 10.0637 6.04839C10.3782 6.3172 10.6298 6.65323 10.8814 6.98925C12.8312 6.58602 14.3408 6.92204 15.5359 7.66129C15.9133 7.86291 16.2906 8.13172 16.6051 8.46774C16.668 8.53495 16.7309 8.53495 16.7309 8.60215C16.9825 8.87097 17.2341 9.13979 17.4228 9.4086C18.3034 10.2823 18.6808 11.2903 18.6808 11.8952Z" />
                    <path d="M6.85591 5.24194C7.35909 5.30914 7.86228 5.51075 8.17677 5.91398C8.49126 6.3172 8.61706 6.85484 8.74286 7.32527C8.80575 7.7285 8.86865 8.13172 8.99445 8.46774C9.05735 8.66935 9.18314 8.80376 9.24604 8.93817C9.30894 9.07258 9.43474 9.20699 9.49763 9.3414V9.47581C9.30894 9.67742 8.86865 9.47581 8.67996 9.3414C8.36547 9.20699 8.11387 8.93817 7.86228 8.66936C7.1704 7.86291 6.79301 6.65323 6.79301 5.57796C6.79301 5.51075 6.85591 5.37634 6.85591 5.24194Z" />
                    <path d="M15.0327 14.6505C13.9634 17.8091 18.8066 19.9597 16.9825 23.1855C18.8066 22.379 19.6872 19.8925 18.9324 17.9435C18.3034 16.1962 16.2906 15.5242 15.0327 14.6505Z" />
                    <path d="M8.67996 18.75C8.99445 18.5484 9.30894 18.3468 9.62343 18.1452C9.93792 18.0108 10.3153 17.8763 10.6927 17.8091C11.3846 17.6747 12.1394 17.6075 12.7054 17.3387C13.0199 17.2043 13.2715 17.0699 13.5231 16.8011C13.7747 16.5995 13.9005 16.3306 14.0263 15.9946C14.1521 15.6586 14.1521 15.3226 14.1521 14.9866C14.1521 14.5833 14.0263 14.2473 13.9005 13.9113C14.1521 14.1801 14.3408 14.5833 14.4666 14.9866C14.5924 15.3898 14.5924 15.793 14.5295 16.1962C14.4666 16.5995 14.215 17.0027 13.9634 17.3387C13.7118 17.6075 13.3344 17.8763 12.957 18.0108C12.5796 18.1452 12.2023 18.2124 11.8249 18.2796C11.4475 18.2796 11.133 18.3468 10.7556 18.3468C10.0637 18.3468 9.37184 18.4812 8.67996 18.75Z" />
                    <path d="M15.473 24.328C15.3472 24.3952 15.2843 24.5296 15.1585 24.5968C15.0327 24.664 14.9069 24.7312 14.7811 24.7984C14.5295 24.9328 14.215 25 13.9005 25C13.0828 25 12.5168 24.328 12.2023 23.5887C11.9507 23.1183 11.8249 22.5806 11.5733 22.1102C11.1959 21.4382 10.504 20.9005 9.74923 20.9677C9.43474 21.0349 9.12024 21.1694 8.99445 21.4382C8.55416 22.1774 9.18314 23.2527 10.0637 23.1183C10.1266 23.1183 10.1895 23.1183 10.2524 23.0511C10.3153 23.0511 10.3782 22.9839 10.4411 22.9167C10.5669 22.7823 10.6298 22.6479 10.6927 22.5134C10.7556 22.379 10.7556 22.1774 10.7556 21.9758C10.6927 21.7742 10.6298 21.6398 10.504 21.5726C10.6927 21.6398 10.8185 21.7742 10.8814 21.9758C10.8185 22.3118 10.8185 22.5134 10.8185 22.7151C10.7556 22.9167 10.6927 23.1183 10.5669 23.2527C10.504 23.3199 10.4411 23.3871 10.3153 23.4543C10.2524 23.5215 10.1266 23.5887 10.0637 23.5887C9.87502 23.6559 9.68633 23.6559 9.49763 23.6559C9.24604 23.5887 8.93155 23.5215 8.74286 23.3199C8.49126 23.1183 8.30257 22.7823 8.05097 22.5806C7.79938 22.2446 7.48489 22.043 7.1075 21.8414C6.85591 21.707 6.60432 21.6398 6.35272 21.5726C6.22693 21.5726 6.10113 21.5054 5.97533 21.5054C5.91243 21.5054 5.59794 21.4382 5.59794 21.4382C6.03823 21.035 6.47852 20.6989 6.91881 20.3629C7.42199 20.0269 7.92518 19.8253 8.49126 19.6237C9.05735 19.422 9.62343 19.422 10.2524 19.4893C10.5669 19.5565 10.8185 19.6237 11.133 19.7581C11.4475 19.8925 11.6991 20.0941 11.9507 20.2957C12.2023 20.5645 12.391 20.8333 12.5168 21.1022C12.6425 21.371 12.7054 21.707 12.7683 22.043C12.957 22.9839 12.8941 24.4624 14.0263 24.664C14.0892 24.664 14.1521 24.664 14.215 24.664H14.4037C14.5295 24.664 14.6553 24.664 14.7811 24.5968C14.9698 24.5968 15.2214 24.4624 15.473 24.328Z" />
                    <path d="M7.73648 10.0134C7.67359 10.2151 7.61069 10.3495 7.54779 10.4839C7.35909 10.7527 7.1075 10.9543 6.85591 11.0887C6.60432 11.2231 6.35272 11.2903 6.03823 11.2903C5.97533 11.2903 5.91244 11.2903 5.84954 11.2903C5.66084 11.2903 5.53504 11.3575 5.34635 11.4919C5.22055 11.6263 5.09476 11.7608 5.09476 11.9624C5.09476 12.0296 5.03186 12.164 5.03186 12.2312C5.03186 12.5 4.96896 12.7016 4.96896 13.0376C4.90606 13.5753 4.78027 14.0457 4.59157 14.5161C4.33998 15.121 4.02549 15.6586 4.08839 16.3306C4.15128 16.8011 4.33998 17.1371 4.65447 17.4059C5.15766 18.0108 6.35272 18.2796 6.10113 19.6909C5.91243 20.5645 4.59157 21.4382 2.76752 21.7742C2.95622 21.7742 2.51593 20.9677 2.51593 20.9005C2.32723 20.5645 2.07564 20.2285 1.94985 19.8925C1.63535 19.2204 1.50956 18.3468 1.63535 17.6075C1.76115 16.8011 2.32724 16.1962 2.76752 15.5242C3.33361 14.7849 3.89969 13.8441 4.02549 12.836C4.02549 12.6344 4.08839 12.2984 4.15128 12.0296C4.21418 11.6936 4.27708 11.4247 4.40288 11.0887C4.46577 10.8871 4.59157 10.7527 4.78027 10.5511C4.84316 10.4839 4.90606 10.3495 4.90606 10.2823C4.90606 10.1479 4.90606 10.0806 4.84316 9.94624L1.88695 4.23387L6.16403 9.87903C6.22693 9.94624 6.28982 10.0134 6.35272 10.0134C6.41562 10.0806 6.47852 10.0806 6.60431 10.0806C6.66721 10.0806 6.73011 10.0806 6.85591 10.0134C6.91881 9.94624 6.9817 9.94624 7.0446 9.87903C7.1075 9.81183 7.1075 9.74462 7.1075 9.61022C7.1075 9.54301 7.1075 9.4086 7.0446 9.3414C6.79301 8.93817 6.47852 8.60215 6.16403 8.19893L2.95622 3.96506L0 0L3.20781 3.69624L6.60431 7.7285C6.9817 8.19893 7.35909 8.60215 7.73648 9.13978L7.79938 9.20699V9.3414C7.79938 9.61022 7.79938 9.81183 7.73648 10.0134Z" />
                    <path d="M8.74286 23.2527C8.55416 23.0511 8.36547 22.8495 8.17677 22.6479C8.36547 22.9167 8.49126 23.1183 8.74286 23.2527Z" />
                  </svg>
                  <span className="max-h-[21px]">Buy Now</span>
                </div>
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center px-4 pt-[22px] md:pt-[11px] pb-[42.09px] md:pb-[89.26px] relative z-0">
            {/* <img className="h-[47px]" src="images/feed.png" alt="" /> */}
            <span className="font-Snippet text-[30px] leading-[50px] shadow3 feed">Feed Village With</span>
            <span className="mt-[5.81px] md:mt-[13px] font-Snippet text-[100px] leading-[91.5px] text-green1 shadow5 stroke3">
              <span className="text-[75px] font-Snippet">$</span>
              Jeet
            </span>
            <div className="mt-[17px] md:mt-[13px] relative">
              <img className="w-[185px] h-[152px] md:w-auto md:h-auto relative" src="images/logo.svg" alt="" />
            </div>
            <span className="mt-[22.54px] md:mt-[18.54px] font-semibold text-[16px] md:text-[20px] leading-[18.56px] md:leading-[120%] text-center relative text-black1">My friend, $JEET is the next big popular memecoin isn't it??</span>
            <button className="flex justify-center items-center px-[23px] h-[44px] bg-white transition border-2 border-black1 rounded-[30px] font-semibold text-black1 hover:text-orange1 text-[16px] leading-[150%] shadow-md mt-5 md:mt-[25.54px] space-x-[9px] relative max-w-full" onClick={handleCopy}>
              <span>{text}</span>
              {/* <span className="hidden md:block whitespace-nowrap">Contract Address: 0x8C0f2b1012352560921bfDBf4F9aF9EAed78Dba6</span>
              <span className="block md:hidden whitespace-nowrap">Contract Address: 0x8C0f2...Dba6</span> */}
              <svg className="w-[18.48px] h-[20px] md:w-[17px]" width="17" height="21" viewBox="0 0 17 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 20.5369C1.45 20.5369 0.979002 20.3409 0.587002 19.9489C0.195002 19.5569 -0.000664969 19.0862 1.69779e-06 18.5369V5.53687C1.69779e-06 5.25353 0.0960018 5.01587 0.288002 4.82387C0.480002 4.63187 0.717335 4.5362 1 4.53687C1.28333 4.53687 1.521 4.63287 1.713 4.82487C1.905 5.01687 2.00067 5.2542 2 5.53687V18.5369H12C12.2833 18.5369 12.521 18.6329 12.713 18.8249C12.905 19.0169 13.0007 19.2542 13 19.5369C13 19.8202 12.904 20.0579 12.712 20.2499C12.52 20.4419 12.2827 20.5375 12 20.5369H2ZM6 16.5369C5.45 16.5369 4.979 16.3409 4.587 15.9489C4.195 15.5569 3.99934 15.0862 4 14.5369V2.53687C4 1.98687 4.196 1.51587 4.588 1.12387C4.98 0.731867 5.45067 0.5362 6 0.536867H15C15.55 0.536867 16.021 0.732867 16.413 1.12487C16.805 1.51687 17.0007 1.98753 17 2.53687V14.5369C17 15.0869 16.804 15.5579 16.412 15.9499C16.02 16.3419 15.5493 16.5375 15 16.5369H6Z" />
              </svg>

            </button>
            <div className="flex text-[16px] md:text-[20px] leading-[26px] md:leading-[32.98px] space-x-1 mt-[23.44px] md:mt-[17.2px] relative text-black1">
              <span>Available on</span>
              <img className="mt-[-8px]" src="images/uniswap.svg" alt="" />
              <span className="font-bold">Uniswap</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white text-black1">
        <div className="mx-auto max-w-[1218px] relative">
          <div className="flex justify-center xl:justify-between items-center xl:pt-[127px] xl:pb-[74px]">
            <img className="hidden xl:block w-[468.64px]" src="images/manifest/jeet.png" alt="" />
            <div className="flex flex-col items-center xl:items-start pt-[60px] pb-[49px] xl:py-0 px-4">
              <span className="font-bold text-[30px] leading-[40px]">The JEET Manifesto</span>
              <img className="block xl:hidden w-[279px] mt-10" src="images/manifest/jeet-mobile.png" alt="" />
              <span className="mt-10 xl:mt-3 text-center xl:text-left max-w-[598px] relative">
                Calling all JEETS! Tired of those snobby Inu coins hogging the memecoin spotlight, isn't it? Well, move over, little pups, because $JEET is in the house now! It is time for the scrappy, meme-tastic underdog to show the crypto world what it is all about, my friend.<br/><br/>
                You know the feeling, right? Searching for little scraps in the crypto jungle while those Inu coins keep on going and going. But no more, I say! $JEET is here to make memecoins cool, relatable, and downright degenerate again.<br/><br/>
                We said no, no to those fancy presales and sneaked into the crypto scene all stealth-like. And guess what, my friend? No taxes, burnt LP, and a renounced contract. $JEET has got your back, my fellow crypto misfits. Fueled by the unstoppable power of the dankest memes, let $JEET take you on a wild ride to the moon and even beyond!<br/><br/>
                So, what are you waiting for, Jeet? Grab your bags, hold on tight, and get ready for the $JEET revolution. This is the memecoin for the true degenerates, the ones who are not afraid to have some fun. Strap in and let's go because, with $JEET, there is no limit to how high we can fly, my friend! Let us get this Naan!
                <img className="hidden xl:block absolute bottom-[calc(100%+12px)] right-[-22px] w-[100px]" src="images/manifest/naan.png" alt="" />
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-center pb-[78px] xl:pb-[145px]">
            <span className="font-Snippet text-[50px] xl:text-[75px] leading-[183%] xl:leading-[122%] text-orange1 shadow5 title">How To Buy</span>
            <span className="text-[14px] xl:text-[16px] leading-[17px] xl:leading-[18.96px] text-grey1">Its super easy to acquire $JEET.</span>
            <div className="flex flex-col xl:flex-row justify-center mt-[30px] xl:mt-[58px] space-y-[15px] xl:space-x-[15px] xl:space-y-0">
              <div className="flex flex-col items-center py-[23px] px-4 w-[236px] min-h-[300px] xl:min-h-[340px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="min-h-[100px]"><img src="images/how-to-buy/1.svg" alt="" /></div>
                <span className="mt-4 text-[14px] leading-[19.81px] text-black1 text-center">
                  <span className="font-bold text-[18px] leading-[21.33px]">Create Wallet.</span><br/><br/>
                  Download and install a wallet like Metamask on your browser, or if on mobile from the App Store or Google Play Store.
                </span>
              </div>
              <div className="flex flex-col items-center py-[23px] px-4 w-[236px] min-h-[300px] xl:min-h-[340px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="min-h-[100px]"><img src="images/how-to-buy/2.svg" alt="" /></div>
                <span className="mt-4 text-[14px] leading-[19.81px] text-black1 text-center">
                  <span className="font-bold text-[18px] leading-[21.33px]">Buy ETH</span><br/><br/>
                  JEET is on the Ethereum blockchain so you need ETH to purchase. You can purchase ETH inside wallets or withdraw from an exchange.
                </span>
              </div>
              <div className="flex flex-col items-center py-[23px] px-4 w-[236px] min-h-[300px] xl:min-h-[340px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="min-h-[100px]"><img src="images/how-to-buy/3.svg" alt="" /></div>
                <span className="mt-4 text-[14px] leading-[19.81px] text-black1 text-center">
                  <span className="font-bold text-[18px] leading-[21.33px]">Go to Uniswap</span><br/><br/>
                  Go to app.uniswap.org and connect your wallet. Paste the $JEET contract into the bottom box and click "I understand".
                </span>
              </div>
              <div className="flex flex-col items-center py-[23px] px-4 w-[236px] min-h-[300px] xl:min-h-[340px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="flex min-h-[100px]"><img src="images/how-to-buy/4.svg" alt="" /></div>
                <span className="mt-4 text-[14px] leading-[19.81px] text-black1 text-center">
                  <span className="font-bold text-[18px] leading-[21.33px]">Swap ETH for $JEET</span><br/><br/>
                  Enter how much ETH you want to buy with in the top box. Then click swap and then confirm when your wallet pops up.
                </span>
              </div>
              <div className="flex flex-col items-center py-[23px] px-4 w-[236px] min-h-[300px] xl:min-h-[340px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="min-h-[100px]"><img src="images/how-to-buy/5.svg" alt="" /></div>
                <span className="mt-4 text-[14px] leading-[19.81px] text-black1 text-center">
                  <span className="font-bold text-[18px] leading-[21.33px]">Welcome JEET</span><br/><br/>
                  You're officially a JEET, come say hi in our Jeet friendly village!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img className="absolute top-0 right-0 w-[242px] h-[248px] xl:w-[521px] xl:h-[521px] object-cover" src="images/roadmap/flower1.png" alt="" />
        <img className="absolute bottom-0 left-0 w-[279px] h-[334px] xl:w-[521px] xl:h-[521px] object-cover" src="images/roadmap/flower2.png" alt="" />
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-center pt-[33px] pb-[65px] xl:pt-[77px] xl:pb-[98px] relative overflow-hidden">
            <span className="mb-5 xl:mb-[33px] font-Snippet text-[50px] xl:text-[75px] leading-[183%] xl:leading-[122%] shadow5 title">Roadmap</span>
            <div className="flex flex-col xl:flex-row justify-center items-center space-y-[25px] xl:space-y-0 xl:space-x-[30px] relative z-[1]">
              <div className="flex flex-col items-start py-[23px] px-[30px] w-[343px] xl:w-[360px] min-h-[264px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="px-[18.5px] py-[5px] font-bold font-Inter text-[25px] leading-[120%] text-white bg-black1 rounded-tr-[5px] rounded-bl-[5px]">Phase 1</div>
                <ul className="mt-2 -ml-5 text-[16px] leading-[175%] text-black1">
                  <li>Community Created</li>
                  <li>CoinGecko/CMC Listings</li>
                  <li>500 Holders reached</li>
                  <li>$JEET listed on Uniswap</li>
                  <li>$JEET liquidity burned</li>
                </ul>
              </div>
              <div className="flex flex-col items-start py-[23px] px-[30px] w-[343px] xl:w-[360px] min-h-[264px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="px-[18.5px] py-[5px] font-bold font-Inter text-[25px] leading-[120%] text-white bg-black1 rounded-tr-[5px] rounded-bl-[5px]">Phase 2</div>
                <ul className="mt-2 -ml-5 text-[16px] leading-[175%] text-black1">
                  <li>Community partnerships</li>
                  <li>$JEET meme/shill competitions</li>
                  <li>First CEX listing</li>
                  <li>2,000 Holders reached</li>
                  <li>First major $JEET token utility revealed</li>
                </ul>
              </div>
              <div className="flex flex-col items-start py-[23px] px-[30px] w-[343px] xl:w-[360px] min-h-[264px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                <div className="px-[18.5px] py-[5px] font-bold font-Inter text-[25px] leading-[120%] text-white bg-black1 rounded-tr-[5px] rounded-bl-[5px]">Phase 3</div>
                <ul className="mt-2 -ml-5 text-[16px] leading-[175%] text-black1">
                  <li>Jeet merch</li>
                  <li>Jeet Village</li>
                  <li>Jeet Tools</li>
                  <li>5,000 Holders reached</li>
                  <li>Meme Takeover</li>
                  <li>5 New CEX listings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-center pt-10 pb-[68.25px] px-4 xl:py-[105px]">
            <span className="font-Snippet text-[50px] xl:text-[75px] leading-[183%] xl:leading-[122%] text-orange1 shadow5 title">Tokenomics</span>
            <span className="xl:mt-[10px] text-[14px] xl:text-[16px] leading-[17px] xl:leading-[18.96px] text-grey1">100 trillion $JEET to feed our village.</span>
            <div className="flex flex-col xl:flex-row items-center xl:items-start mt-10 xl:space-x-[73px]">
              <div className="flex flex-col items-center">
                <span className="font-Snippet text-[25px] leading-[115.12%] xl:text-[35px] text-center text-orange1 shadow2 supply">$JEET Token Supply<br/>100,000,000,000,000</span>
                <div className="flex flex-col items-start px-6 py-9 xl:px-10 xl:py-[41px] mt-10 xl:mt-[23px] w-[343px] xl:w-[586px] min-h-[264px] bg-orange2 rounded-tr-[15px] rounded-bl-[15px] border-2 border-black1 shadow-1xl">
                  <div className="text-[14px] leading-[24.5px] xl:text-[16px] xl:leading-[28px] text-black1">
                    <span className="font-bold text-[18px] leading-[31.5px] xl:text-[20px] xl:leading-[35px]">0% Tax. 100% Jeet friendly.</span><br/><br/>
                    A total of 93% of tokens are allocated to the liquidity pool, Liquidity Pool got burnt, and the contract was renounced. The remaining 7% of the supply is reserved exclusively for future centralized exchange listings, bridges, and liquidity pools.
                  </div>
                </div>
              </div>
              <img className="block xl:hidden mt-[50px] px-5 w-[303px]" src="images/tokenomics/piechart-mobile.png" alt="" />
              <img className="hidden xl:block w-[402px]" src="images/tokenomics/piechart.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
