import React, { useMemo } from "react";
import PropTypes from "prop-types";

import useWindowDimensions from "../hooks/useDimensions";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;
  useMemo(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    if(screenWidth > 0 && screenHeight > 0) appHeight()
  }, [screenWidth, screenHeight])
  return (
    <div className="flex flex-col min-h-full relative">
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
